import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import Hero from '../components/Hero';
import Button from '../components/PageComponents/Button';
import OverlapSection from '../components/PageComponents/OverlapSection';
import { Subgrid, TwoColumnGrid } from '../styles/Grids';
import PageStyles from '../styles/PageStyles';

export default function DigimarkkinointiPage() {
  return (
    <PageStyles>
      <Helmet
        htmlAttributes={{
          lang: 'fi',
        }}
      >
        <title>Digimarkkinointia markkinointi edellä - Digis</title>
        <meta
          name="description"
          content="Yhdistä strateginen suunnittelu ja käytännön digimarkkinoinnin toteutus ja jalkautus päivittäiseen liiketoimintaan. Hae yritystukea markkinoinnin digitalisaatioon. Kysy lisää."
        />
      </Helmet>
      <Hero>
        <span>DIGI&shy;MARKKINOINTIA</span>
        <br /> MARKKINOINTI
        <br /> EDELLÄ.
      </Hero>
      <TwoColumnGrid>
        <Subgrid>
          <span className="bar" />
          <div className="highlight">
            <h2>
              <span className="bold">ELY</span>-kehittämispal&shy;veluiden{' '}
              <span className="cursive">
                avulla yritys käynnistää oman digi&shy;markkinoinnin{' '}
              </span>
              tehostamisen.
            </h2>
            <Button>Tilaa maksuton opas.</Button>
          </div>
        </Subgrid>
        <div>
          <h2>
            <span className="cursive">Jokainen yritys</span>
            <br />
            voi parantaa kannattavuutta,
            <br />
            kasvaa ja karsia kustannuksia.
          </h2>
          <p>
            Digimarkkinoinnin tehokkuus perustuu moneen tekijään. Digin
            mediatila on usein edullisempaa. Myös kohderyhmä voidaan rajata
            tarkasti vain tiettyihin segmentteihin, maantieteellisesti jopa
            tiettyihin kaupunkeihin.
          </p>
          <p>
            Verkossa tehty tilaus tai ostos on selkeästi edullisempi verrattuna
            kivijalkakauppaan tai puhelinmyyntiin. Vaikka tilausten ja liidien
            klousaaminen tehtäisiin edelleen oman myyjän toimesta,
            digitaalisista kanavista ohjattu liidi on kustannuksiltaan
            huomattavasti edullisempi verrattuna kylmäsoitoilla kerättyyn
            potentiaaliin.
          </p>

          <p>
            Digitaalinen asiakaspalvelu on nopeaa ja tehokasta. Tästä syystä se
            on asiakkaalle myös parempaa palvelua. Digitaalisuuden avulla
            markkina avautuu isolle ja pienelle yritykselle samankokoisena.
          </p>

          <p>
            Digitaalinen Markkinointi Digis on{' '}
            <span className="bold">
              <Link className="link" to="/ely-kehittamispalvelut">
                ELY-keskuksen yritysten kehittämispalveluiden
              </Link>
            </span>{' '}
            hyväksytty{' '}
            <a
              className="bold link"
              href="https://sahkoinenasiointi.ahtp.fi/fi/asiantuntijahaku?hakusana=Merja%20Saari"
              target="_blank"
              rel="noreferrer"
            >
              palveluntuottaja.
            </a>{' '}
            Tilaa maksuton ELY-opas. Käynnistä markkinoinnin ja asiakkuuksien
            kehittäminen ELY-keskuksen tarjoaman taloudellisen tuen ja
            huippuasiantuntijoiden avulla.
          </p>
        </div>
      </TwoColumnGrid>
      <OverlapSection>
        <div className="title-section">
          <h2>
            <span className="cursive">
              <span style={{ fontSize: '1.4em' }}>Tehtävämme</span> on
            </span>
            <br />{' '}
            <span className="cursive" style={{ paddingLeft: '6rem' }}>
              korvata{' '}
            </span>
            tehoton markkinointi.
          </h2>
        </div>
        <div className="overlap" />
        <div className="content-section">
          <div>
            <h3 className="cursive">Tilanneanalyysi</h3>
            <p>
              kilpailija-, markkina- ja
              <br /> kohderyhmäanalyysi
            </p>
          </div>
          <div>
            <h3 className="cursive">Markkinointi</h3>
            <p>
              strateginen suunnittelu yrityksen
              <br /> kehittämistarpeet huomioiden
            </p>
          </div>
          <div>
            <h3 className="cursive">Myynti+Markkinointi</h3>
            <p>
              integrointi, asiakkuuksien <br /> kehittäminen ja hallinta
            </p>
          </div>
          <div>
            <h3 className="cursive">Koulutus</h3>
            <p>
              markkinoinnin, digin, myynnin
              <br /> ja viestinnän jalkautus
              <br />
              liiketoiminnan arkeen
            </p>
          </div>
          <div>
            <h3 className="cursive">Verkkoliiketoiminta </h3>
            <p>
              käynnistäminen, verkkokaupan <br />
              ylläpito ja kannattavuuden
              <br />
              kehittäminen
            </p>
          </div>
          <div>
            <h3 className="cursive">Verkkonäkyvyys</h3>
            <p>
              verkkosivun löydettävyyden ja <br />
              käytettävyyden kehittäminen,
              <br />
              hakukoneoptimointi
            </p>
          </div>
          <div>
            <h3 className="cursive">Viestintä+Sisältö</h3>
            <p>
              sisällön kehittäminen, sisältöstrategia,
              <br /> viestinnän integrointi eri kanavissa, <br />
              sisällöntuotanto
            </p>
          </div>
          <div>
            <h3 className="cursive">Sosiaalinen media</h3>
            <p>
              haltuunotto, kanavastrategia
              <br /> ja kanavien keskinäinen
              <br /> integrointi
            </p>
          </div>
          <div>
            <h3 className="cursive">Google Adwords</h3>
            <p>
              mainonta ja analytiikka, koulutus
              <br /> ja siirto yrityksen omaan
              <br />
              markkinointiarkeen
            </p>
          </div>
        </div>
      </OverlapSection>
    </PageStyles>
  );
}
