import styled from 'styled-components';

// ! Not used? */
export const SectionStyles = styled.section`
  padding: 3rem 1.5rem;
`;

export const ColumnGrid = styled.section``;

export const TwoColumnGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: var(--margin);
  column-gap: var(--margin);

  @media (min-width: 1408px) {
  }
  @media (max-width: 1216px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 768px) {
    row-gap: calc((var(--margin)) * 3);
    grid-template-columns: 1fr;
  }
`;

export const ThreeColumnGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (min-width: 1408px) {
  }
  @media (max-width: 1216px) {
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 768px) {
  }
`;

// ! > Span & div, find a better place (pagestyles) & add class */
export const Subgrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 5rem 5rem repeat(10, auto);
  h2 {
    margin-top: 0;
  }
  .bar {
    grid-column: 3 / 7;
    grid-row: 1;
    @media (max-width: 768px) {
      grid-column: 3 / span 8;
    }
  }
  .highlight {
    grid-column: 2 / span 6;
    grid-row: 3 / span 10;
    @media (max-width: 768px) {
      grid-column: 2 / span 10;
      grid-row: 2 / span 9;
    }
  }
  .contact {
    grid-column: 2 / span 8;
    grid-row: 3 / span 10;
    @media (max-width: 768px) {
      grid-column: 2 / span 10;
      grid-row: 2 / span 9;
    }
  }
  .stepped-wrapper {
    height: fit-content;
    grid-column: 1 / span 12;
    grid-row: 3 / span 10;
  }
  .question-mark {
    grid-column: 1 / span 12;
    grid-row: 1 / span 12;
  }
`;
