import React from 'react';
import styled from 'styled-components';

const OverlapSectionStyles = styled.div`
  display: grid;
  grid-template-columns: var(--margin) 1fr var(--margin);
  grid-template-rows: auto 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: var(--margin) 1fr var(--margin);
    grid-template-rows: auto auto 1fr;
  }
  .title-section {
    grid-row: 1;
    grid-column: 1 / span 3;
    display: flex;
    flex-direction: column;
    background-color: var(--yellow);
    h2 {
      display: inline-block;
      padding: var(--margin) var(--margin) var(--margin) var(--margin);
      margin-bottom: 0;
      margin-top: 0;
    }
    @media (max-width: 1100px) {
    }
  }
  .overlap {
    min-height: 15rem;
    background-color: var(--yellow);
    grid-row: 2;
    grid-column: 1 / span 3;
  }
  .content-section {
    grid-row: 2 / span 2;
    grid-column: 2 / span 1;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: var(--row-gap);
    column-gap: var(--row-gap);

    padding: min(8vw, calc(2 * var(--margin)));
    background-color: var(--white);

    div {
      display: grid;
      grid-template-rows: auto 1fr;

      justify-items: center;
      /* row-gap: calc((var(--row-gap)) / 2); */
    }
    p {
      font-size: clamp(1.6rem, 3vw, 1.8rem);
      text-align: center;
    }
    h3 {
      margin-bottom: 0;
    }
    img {
      text-align: center;
      height: 6rem;
      margin-bottom: calc((var(--row-gap)) / 2);
    }
    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
      /* grid-template-rows: 1fr 1fr 1fr; */
      grid-grid-auto-rows: 1fr;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-grid-auto-rows: 1fr;
    }
  }
`;

export default function OverlapSection({ children }) {
  return <OverlapSectionStyles>{children}</OverlapSectionStyles>;
}
