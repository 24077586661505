import React from 'react';
import { FaCaretRight } from 'react-icons/fa';
import styled from 'styled-components';

const ButtonStyles = styled.button`
  background-color: var(--yellow);
  margin-top: var(--margin);
  padding: 0.4rem 2rem;
  display: flex;
  line-height: 1;
  transition: transform 0.3s cubic-bezier(1, 0.07, 0, 2.21);
  :hover {
    transition: background-color 0.5s ease-in-out;
    background-color: var(--yellow-lighten);
    transform: scale(1.1);
    transition: transform 0.3s cubic-bezier(1, 0.07, 0, 2.21);
  }
  :focus,
  :active {
    outline: none;
    transform: scale(1.2);
    transition: transform 0.3s cubic-bezier(1, 0.07, 0, 2.21);
  }
  @media (max-width: 768px) {
    margin: var(--margin) auto 0 auto;
  }
`;

export default function Button({ children }) {
  return (
    <ButtonStyles className="cursive" type="submit">
      {children} <FaCaretRight className="icon" />
    </ButtonStyles>
  );
}
